import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  changeOrdersGroupStatus,
  changeOrdersGroupStatusFailure,
  changeOrdersGroupStatusSuccess
} from "../actions/ordersActions";
import { union } from "ts-action";
import { markOrdersReceivedSeenAPI } from "../../axios/markOrdersReceivedSeen";
import { selectToken } from "../../../redux-store/selectors";

const actionType = union({ changeOrdersGroupStatus });

function* changeOrdersGroupStatusSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    yield call(
      markOrdersReceivedSeenAPI,
      token,
      action.payload.orderIds,
      action.payload.status
    );
    yield put(changeOrdersGroupStatusSuccess(action.payload));
  } catch (e) {
    put(changeOrdersGroupStatusFailure(action.payload));
  }
}

export function* watchChangeOrdersGroupStatusSaga() {
  yield takeEvery(changeOrdersGroupStatus.type, changeOrdersGroupStatusSaga);
}
