import { reducer, on } from "ts-action";
import {
  getDeliveryAvgPrepTimeSuccess ,
  changeDeliveryAvgPrepTime
} from "../actions/branchDeliveryAvgPrepTimeActions";
import { withLoadingReducer, ILoadingState } from "./withLoadingState";
import { getDeliveryAveragePreparationAction} from "../../constants/actions";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
import { receiveDeliveryAveragePrepTimeChange } from "../actions/socket-actions";
import { IDeliveryAvgPrepTime } from "../../axios/branchDeliveryAveragePrepTime";

export const branchDeliveryAvgPrepTimeReducer = optimistic(
  withLoadingReducer<IDeliveryAvgPrepTime>(
    reducer(
      [
        on(
          {
            receiveDeliveryAveragePrepTimeChange,
            getDeliveryAvgPrepTimeSuccess,
            changeDeliveryAvgPrepTime
          },
          (s, action) => action.payload
        )
      ],
      { delivery_prep: 0 }
    ),
    getDeliveryAveragePreparationAction
  )
) as Reducer<OptimisticState<IDeliveryAvgPrepTime & ILoadingState>>;
