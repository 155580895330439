import { WrappedFieldProps } from "redux-form";
import * as React from "react";
import * as styles from "./styles.module.sass";

interface IProps {
  topText: string;
  bottomText: string;
}

export const getClassNamesFromBooleans = (
  booleans: { [x: string]: boolean },
  stylesModule: { [x: string]: string }
) => {
  return Object.keys(booleans)
    .filter(key => booleans[key])
    .reduce((acc, nextKey) => {
      return `${acc} ${stylesModule[nextKey] || ""}`;
    }, "");
};

export const TopBottomInput: React.FC<WrappedFieldProps & IProps> = ({
  bottomText,
  topText,
  input,
  meta,
  ...props
}) => {
  const { valid, active, touched, invalid } = meta;
  const classNames = React.useMemo(
    () =>
      getClassNamesFromBooleans(
        {
          active: !!active,
          invalid: touched && invalid,
          valid: touched && valid
        },
        styles
      ),
    [meta]
  );

  return (
    <div className={`${styles.wrapper} ${classNames}`}>
      <span className={styles.topText}>{topText}</span>
      <input
        type="number"
        className={`${styles.input}`}
        {...input}
        {...props}
      />
      <span className={styles.bottomText}>{bottomText}</span>
    </div>
  );
};
