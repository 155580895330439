import React from "react";
import * as styles from "./styles.module.sass";
import { useTranslation } from "../../../../../../hooks/useTranslation";
import * as Strings from "../../../../../../i18n/strings/newOrder";
import { PhoneNumberItem } from "../../../../../../../web-components/Login/CustomerServiceCall/modal/PhoneNumber";
import { hot } from "react-hot-loader";
import { useRtlClass } from "../../../../../../../lib";
interface IProps {
  goToInitialChoices: () => void;
  phoneNumber: string;
}
const ContactClient: React.FC<IProps> = props => {
  const { t } = useTranslation("newOrder");
  const { goToInitialChoices: hideAll, phoneNumber } = props;
  return (
    <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
      <div className={styles.contactSupport} onClick={hideAll}>
        <img
          className={styles.arrowRight}
          src={require("../../../../../../assets/arrow-right.svg")}
        />
        <span className={styles.title}>{t(Strings.contactClient)}</span>
      </div>
      <div className={styles.contactWrapper}>
        <PhoneNumberItem>{phoneNumber}</PhoneNumberItem>
      </div>
    </div>
  );
};

export default hot(module)(ContactClient);
