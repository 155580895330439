import { IOrder } from "../../../../redux/reducers/lib";

export const totalInvoice = (orderData: IOrder) => {
  return Number(
    Number(orderData.total_invoices || 0) +
      Number(orderData.delivery_fees || 0) || 0
  ).toFixed(2);
};

export const fleetTotalInvoice = (orderData: IOrder) => {
  return Number(Number(orderData.total_invoices || 0) || 0).toFixed(2);
};
