export default {
  LateReplyReason:
    "ordering became off automatically due to your lateness in accepting new orders",
  Reopen: "Turn the ordering on",
  NoConnectionReason:
    "ordering became off because there is no internet connection",
  NoConnectionSolution:
    "try to connect to internet and you'll start receiving orders again",
  NoOrdersReason: "There are no recent orders",
  NoOrdersSolution: "you will receive notification once a new order arrives",
  NoOrdersReceivedReason: "the branch is closed",
  NoOrdersReceivedSolution:
    "you will receive notification once a new orders arrives",

  StoppedOrders: "orders are turn off",
  ReOrders: "Turn the ordering on",
  ThereIsOrders: "Currently, there are orders in preparation tab",
  ThereAreNewOrders: "There are new orders"
};
