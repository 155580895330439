import { takeLatest } from "redux-saga/effects";
import { delay } from "redux-saga/effects";
import { ACTION_SWITCH_APPS } from "../../constants";
function* switchAppSaga() {
  yield delay(5000);
  // location.reload();
}

export function* watchSwitchAppSaga() {
  yield takeLatest(ACTION_SWITCH_APPS, switchAppSaga);
}
