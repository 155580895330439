import { call, put, select, takeLatest } from "redux-saga/effects";
import { initDB } from "../../db";
import * as constants from "../../constants";
import * as actions from "../actions";
import { CashierWebDB } from "../../db/CashierWebDB";
import persistStorage from "../../../Utils/persistStorage";
import isStoragePersisted from "../../../Utils/isStoragePersisted";
import { selectIsLoggedIn, selectLastUpdatedTime } from "../selectors";
import {
  initializeAppData,
  initializeCountryCode
} from "./util/initalizeCountryCode";

interface IGenericObject {
  [x: string]: any;
}

interface IOptionalObject {
  [x: string]: any;
  $isEmpty?: boolean;
}

const optionalizeObject = (obj: IGenericObject): IOptionalObject => {
  return new Proxy(obj, {
    get: (target, p: string) => {
      return p in target ? target[p] : optionalizeObject({ $isEmpty: true });
    }
  });
};

function* openDBSaga() {
  try {
    const isLoggedIn: boolean = yield select(selectIsLoggedIn);
    if (isLoggedIn) {
      const db: CashierWebDB = yield call(initDB);
      const storageIsPersisted = yield call(isStoragePersisted);
      if (!storageIsPersisted) {
        yield call(persistStorage);
      }
      yield put(actions.openDBSuccess(db));
      const lastUpdatedTime = yield select(selectLastUpdatedTime);
      if (lastUpdatedTime !== 1) {
        yield* initializeCountryCode(db);
        yield* initializeAppData();
      }
      yield put(actions.syncApp());
    } else {
      yield put(actions.openDBFailure(new Error("user not logged in")));
    }
  } catch (error) {
    yield put(actions.openDBFailure(error));
  }
}

export function* watchOpenDBSaga() {
  yield takeLatest(constants.openDBAction.requested, openDBSaga);
}
