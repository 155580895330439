import * as React from "react";
import { WrappedFieldProps } from "redux-form";
import * as styles from "./styles.module.sass";
import { useRtlClass } from "../../../../lib";
import { getClassNamesFromBooleans } from "../top-bottom-input";

interface IProps extends WrappedFieldProps {
  label: string;
}

let count = 0;
const uniqueId = () => count++;

export const IncreaseDecreaseInput: React.FC<IProps> = ({
  label,
  input,
  meta
}) => {
  const id = React.useMemo(() => `idi-${uniqueId()}`, []);
  const { valid, active, invalid, touched } = meta;
  const classNames = React.useMemo(
    () =>
      getClassNamesFromBooleans(
        {
          active: !!active,
          invalid: touched && invalid,
          valid: touched && valid
        },
        styles
      ),
    [meta]
  );

  const increase = React.useCallback(() => {
    input.onChange((input.value || 0) + 1);
  }, [input]);
  const decrease = React.useCallback(() => {
    input.onChange((input.value || 0) - 1);
  }, [input]);

  const handleBlur = React.useCallback(() => {
    input.onBlur(input.value);
  }, [input]);

  return (
    <div
      onFocus={input.onFocus}
      onBlur={handleBlur}
      className={`${styles.wrapper} ${useRtlClass(styles)} ${classNames}`}
    >
      <label htmlFor={id}>{label}</label>
      <div className={styles.inputWrapper}>
        <button onClick={decrease} className={styles.button} type="button">
          <img src={require("../../../assets/minus.svg")} />
        </button>
        <input id={id} type="number" className={styles.input} {...input} />
        <button onClick={increase} className={styles.button} type="button">
          <img src={require("../../../assets/plus.svg")} />
        </button>
      </div>
    </div>
  );
};
