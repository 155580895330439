import { action, props, payload } from "ts-action";
import { BEGIN } from "redux-optimistic-ui";

let id = 0;

const uuid = () => id++;

export const optAction = <P = void>(
  type: string,
  payloadType: { _p: P; _as: "payload" } = payload<P>()
) => {
  const creator = (p2: P) =>
    action(
      type,
      props<{
        payload: typeof payloadType._p;
        meta: { optimistic: { type: string; id: number } };
      }>()
    )({ meta: { optimistic: { type: BEGIN, id: uuid() } }, payload: p2 });

  creator.type = type;

  return creator;
};
