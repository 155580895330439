import { action, payload } from "ts-action";
import {
  getDeliveryAveragePreparationAction,
  deliveryAveragePreparationAction
} from "../../constants/actions";
import { optAction } from "./lib";
import { IDeliveryAvgPrepTime } from "../../axios/branchDeliveryAveragePrepTime";



export const getDeliveryAvgPrepTime = optAction(
  getDeliveryAveragePreparationAction.requested
);
export const getDeliveryAvgPrepTimeSuccess = action(
  getDeliveryAveragePreparationAction.fulfilled,
  payload<IDeliveryAvgPrepTime>()
);
export const getDeliveryAvgPrepTimeFailure = action(
  getDeliveryAveragePreparationAction.rejected
);




export const changeDeliveryAvgPrepTime = optAction(
  deliveryAveragePreparationAction.requested,
  payload<IDeliveryAvgPrepTime>()
);
export const changeDeliveryAvgPrepTimeSuccess = action(
  deliveryAveragePreparationAction.fulfilled,
  payload<IDeliveryAvgPrepTime>()
);
export const changeDeliveryAvgPrepTimeFailure = action(
  deliveryAveragePreparationAction.rejected,
  payload<IDeliveryAvgPrepTime>()
);
