import { select, takeLatest, put, call } from "redux-saga/effects";
import { selectToken } from "../../../redux-store/selectors";
import { getStatusReasonAPI } from "../../axios/getStatusReason";
import {
  getStatusReasonFailure,
  getStatusReason,
  getStatusReasonSuccess
} from "../actions/ordersActions";
import { getStatusReasonAction } from "../../constants/actions";
import { union } from "ts-action";
const actionType = union({ getStatusReason });

function* getStatusReasonSaga(action: typeof actionType) {
  try {
    const { order_status } = action.payload;
    const token = yield select(selectToken);
    const res = yield call(getStatusReasonAPI, token, order_status);
    yield put(getStatusReasonSuccess(res.data));
  } catch (e) {
    yield put(getStatusReasonFailure(e));
  }
}

export function* watchGetStatusReasonSaga() {
  yield takeLatest(getStatusReasonAction.requested, getStatusReasonSaga);
}
