import { logCurrentAppVersion, logCurrentAppVersionFailure, logCurrentAppVersionSuccess } from "../actions";
import { takeLatest, call, select, put } from "redux-saga/effects";
import { logCurrentAppVersionApi } from "../../axios/logCurrentAppVersion";
import { selectToken, selectCashierBranchId } from "../selectors";
import { union } from "ts-action";

const actionType = union({ logCurrentAppVersion });
function* logCurrentAppVersionSaga({
  payload: { app_version }
}: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const branch_id = yield select(selectCashierBranchId);
    const res = yield call(logCurrentAppVersionApi, {
      branch_id,
      token,
      app_version
    });
    yield put(logCurrentAppVersionSuccess(res.data));
  } catch (error) {
    yield put(logCurrentAppVersionFailure(error));
  }


}

export function* watchLogCurrentAppVersionSaga() {
  yield takeLatest(logCurrentAppVersion.type, logCurrentAppVersionSaga);
}
