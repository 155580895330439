import * as React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import i18n from "../../../../../i18n/i18n";

export interface ISelectItemData<ValueType = any> {
  label: string;
  value: ValueType;
  image?: string;
}

interface ISelectItemProps {
  item: ISelectItemData;
  onSelect: (item: any) => any;
}

const SelectItem: React.SFC<ISelectItemProps> = ({ item, onSelect }) => {
  const handleSelect = () => onSelect(item.value);
  return (
    <DropdownItem
      className={styles.item}
      style={{
        textAlign: i18n.language === "ar" ? "right" : "left"
      }}
      onClick={handleSelect}
    >
      {item.label}
      {item.image}
    </DropdownItem>
  );
};

interface IRenderDropDownSelectState {
  isOpen: boolean;
}

type DefaultProps = Readonly<typeof defaultProps>;
interface IRenderDropDownSelectProps extends DefaultProps {
  label: string;
  type: string;
  className: string;
  labelClassName?: string;
  disabled: boolean;
  options: ISelectItemData[];
}

const defaultProps = {
  renderSelectToggle: (item: ISelectItemData) => <>{item.label}</>
};

export class RenderDropDownSelect extends React.Component<
  WrappedFieldProps & IRenderDropDownSelectProps,
  IRenderDropDownSelectState
> {
  public static defaultProps = defaultProps;
  public state = {
    isOpen: false
  };

  public toggleSelectMenu = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  public handleBlur = () => {
    const { input } = this.props;
    input.onBlur(input.value);
  };
  public render() {
    const {
      input,
      label,
      meta: { touched, error },
      options,
      className,
      labelClassName
    } = this.props;
    const selectedLabel = options.find(opt => opt.value === input.value) || {
      label,
      value: ""
    };
    return (
      <div className={className}>
        <label htmlFor={input.name} className={styles.label}>
          <span className={labelClassName}>{label}</span>
        </label>
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggleSelectMenu}>
          <DropdownToggle
            disabled={this.props.disabled}
            onBlur={this.handleBlur}
            className={styles.switch}
            caret={true}
          >
            {this.props.renderSelectToggle(selectedLabel)}
          </DropdownToggle>

          <DropdownMenu className={styles.menuWrapper}>
            {options.map((option, index) => (
              <SelectItem key={index} item={option} onSelect={input.onChange} />
            ))}
          </DropdownMenu>
        </Dropdown>
        {touched && error && (
          <p
            style={{
              margin: 0,
              padding: "1rem",
              color: "#db3030",
              fontSize: "1.2rem"
            }}
          >
            *{error}
          </p>
        )}
      </div>
    );
  }
}
