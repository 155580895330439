import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { IStore } from "../../../data-model/types";
import { getStoreAction } from "../../constants/actions";
import { getStoreSuccess } from "../actions/storeActions";

interface IState {
  store: IStore | null;
}

const initialState: IState = {
  store: null
};

export const storeReducer = withLoadingReducer<IState>(
  reducer(
    [
      on(getStoreSuccess, (state, action) => {
        return {
          ...state,
          store: action.payload
        };
      })
    ],
    initialState
  ),
  getStoreAction
);
