import axios from "./axios";
import { storeOrderingStatus } from "./requests";
import { OrderingStatus } from "../components/AvailableRequests/AvailableRequestsModal";

export const getStoreOrderingStatus = (token: string, branch_id: string) => {
  return axios.get<{ pickup_status: OrderingStatus }>(storeOrderingStatus, {
    params: {
      branch_id
    },
    headers: {
      token
    }
  });
};
