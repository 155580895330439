import { ACTION_CLEAR_CUSTOMER, ACTION_SELECT_CUSTOMER, addPointAction } from "../../constants";
import { ICustomer } from "../../data-model/types";
import noop from 'lodash/noop';


export interface IAddPointsAction {
  type: typeof addPointAction.requested;
  payload: (...args) => any;
}

export const addPoints = (successCb = noop): IAddPointsAction => ({
  type: addPointAction.requested,
  payload: successCb,
});

export interface ISelectCustomerAction {
  type: typeof ACTION_SELECT_CUSTOMER;
  payload: ICustomer;
}

export interface IClearCustomerAction {
  type: typeof ACTION_CLEAR_CUSTOMER;
}

export const selectCustomer = (customer: ICustomer): ISelectCustomerAction => ({
  type: ACTION_SELECT_CUSTOMER,
  payload: customer,
});

export const clearCustomer = (): IClearCustomerAction => ({
  type: ACTION_CLEAR_CUSTOMER,
});
