import axios from "./axios";
import * as Requests from "./requests";

export const logCurrentAppVersionApi = ({
  token,
  ...params
}: {
  branch_id: string;
  token: string;
  app_version: string;
}) => {
  return axios.post(Requests.logCurrentAppVersion, params, {
    headers: {
      token
    }
  });
};
