import * as actions from "../../constants";
import {
  ILoginAction,
  ILoginFailureAction,
  ILoginResponse,
  ILoginSuccessAction,
  ISetSelectedCashierBranchAction
} from "../../types/index";
import { IBranch } from "../../data-model/types";
import { action, payload } from "ts-action";

export const login = (): ILoginAction => ({
  type: actions.loginAction.requested
});

export const loginSuccess = (data: ILoginResponse): ILoginSuccessAction => ({
  type: actions.loginAction.fulfilled,
  payload: data
});
export const remoteLoginSuccess = action(
  "ACTION_REMOTE_LOGIN",
  payload<{
    token: string;
    cashier_id: string;
    branch_id: string;
    store_id: string;
    store_pos?: string;
  }>()
);

export const loginFailure = (error: Error): ILoginFailureAction => ({
  type: actions.loginAction.rejected,
  payload: error
});

export const setSelectedCashierBranch = (
  branch: IBranch
): ISetSelectedCashierBranchAction => ({
  type: actions.ACTION_SET_SELECTED_CASHIER_BRANCH,
  payload: branch
});
