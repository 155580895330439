import { call, put, select, takeLatest } from "redux-saga/effects";
import { logoutAction } from "../../constants";
import { selectDB, selectOrderingMenuDB } from "../selectors";
import { logoutFailure, logoutSuccess } from "../actions/logout-actions";
import { CashierWebDB } from "../../db/CashierWebDB";
import { notify } from "react-notify-toast";
import i18n from "../../i18n/i18n";
import { logout } from "../../i18n/strings/App";
import { mustBeConnected } from "../../i18n/strings/logout";
import { clearOrderingMenuDB } from "../../Pickup-App/helpers/orderingMenuDBHelpers";
import { OrderingMenuDB } from "../../Pickup-App/db/orderingMenuDb";

// when logging out of cashier app when initially in ordering app
// the db is non-existent therefore it is always at that case
// null and logout fails, and does not reload the page, which
// causes app level panic

const deleteDB = async (db: CashierWebDB | null) => db && (await db.delete());

function* clearData() {
  const db: CashierWebDB = yield select(selectDB);
  const orderingMenuDBInstance = yield select(selectOrderingMenuDB);
  yield call(clearOrderingMenuDB, (orderingMenuDBInstance as unknown) as OrderingMenuDB)
  yield call(deleteDB, db);
  Object.keys(localStorage).forEach(
    key => key.includes("persist:") && localStorage.removeItem(key)
  );
  window.location.reload();
}

function* logoutSaga() {
  try {
    if (window.navigator.onLine) {
      yield put(logoutSuccess());
      yield* clearData();
      window.location.reload();
    } else {
      notify.show(
        i18n.t(mustBeConnected, {
          ns: logout
        }),
        "error"
      );
    }
  } catch (e) {
    yield put(logoutFailure(e));
  }
}

export function* watchLogoutSaga() {
  yield takeLatest(logoutAction.requested, logoutSaga);
}
