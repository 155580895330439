import { action, payload } from "ts-action";
import {
  ACTION_RESET_LOCK_SCREEN_NOTIFICATION_COUNT,
  ACTION_INCREMENT_LOCK_SCREEN_NOTIFICATION_COUNT,
  ACTION_OFFLINE_DISMISS
} from "../../constants/actions";

export const resetLockScreenNotificationCount = action(
  ACTION_RESET_LOCK_SCREEN_NOTIFICATION_COUNT
);
export const incrementLockScreenNotificationCount = action(
  ACTION_INCREMENT_LOCK_SCREEN_NOTIFICATION_COUNT
);

export const offlineDismiss = action(
  ACTION_OFFLINE_DISMISS,
  payload<boolean>()
);
