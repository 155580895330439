import axios from "./axios";
import { canChooseReason } from "./requests";

export const canChooseReasonAPI = (token: string, orderId: string) => {
  return axios.get(canChooseReason(orderId), {
    headers: {
      token
    }
  });
};
