import { reducer, on } from "ts-action";
import { getStatusReasonSuccess } from "../actions/ordersActions";
import { getStatusReasonAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { IReasons } from "../../constants/types";

interface IStatusReason {
  reasons: number[];
  reasonsById: { [x: number]: IReasons };
}
export const getStatusReasonReducer = withLoadingReducer<IStatusReason>(
  reducer<IStatusReason>(
    [
      on(getStatusReasonSuccess, (state, { payload }) => ({
        ...state,
        reasons: payload.map(i => i.id),
        reasonsById: payload.reduce(
          (acc, next) => ({
            ...acc,
            [next.id]: next
          }),
          {}
        )
      }))
    ],
    {
      reasons: [],
      reasonsById: {}
    }
  ),
  getStatusReasonAction
);
