import { changeOrderTimingStatus } from "../actions/ordersActions";
import { union } from "ts-action";
import { OrderTimingStatus } from "../reducers/lib";
import { takeLatest } from "redux-saga/effects";
import { receiveNewOrder } from "../actions/socket-actions";

const path = require("../../assets/twin.aac").default;
const audio = new Audio(path);

const actionType = union({ changeOrderTimingStatus });
function* changeOrderTimingSaga(action: typeof actionType) {
  if (
    action.payload.newStatus === OrderTimingStatus.overdue &&
    (audio.ended || audio.paused)
  ) {
    audio.play();
  }
}

export function* watchChangeOrderTimingStatus() {
  yield takeLatest(
    [changeOrderTimingStatus.type, receiveNewOrder.type],
    changeOrderTimingSaga
  );
}
