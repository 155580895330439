import { Action } from "redux";

import { put } from "redux-saga/effects";

import { COMMIT, REVERT } from "redux-optimistic-ui";
import { customPickupNotify } from "../../util/custom-pickup-notify";
import i18n from "../../../i18n/i18n";
import * as Strings from "../../i18n/strings/orderingStatus";

type HelperFunc0<A> = (action: A) => any;

const appendOptimistic = (action, type, id) => {
  return {
    ...action,
    meta: {
      ...action.meta,
      optimistic: { type, id }
    }
  };
};

export function handleOptimism<
  A extends Action<string> & {
    payload: any;
    meta: { optimistic: { id: number } };
  }
>(saga: HelperFunc0<A>, { failure, success }) {
  return function*(action: A) {
    const {
      meta: {
        optimistic: { id }
      },
      payload
    } = action;
    try {
      const result = yield* saga(action);
      yield put(appendOptimistic(success(result), COMMIT, id));
    } catch {
      yield put(appendOptimistic(failure(payload), REVERT, id));
      customPickupNotify.error(
        i18n.t(Strings.somethingWentWrong, { ns: "orderingStatus" })
      );
    }
  };
}
