import { select, takeLatest, put, retry } from "redux-saga/effects";
import { selectToken } from "../../../redux-store/selectors";
import { getStoreApi } from "../../axios/getStore";
import * as actions from "../actions/storeActions";

function* getStoreSaga() {
  try {
    const token: string = yield select(selectToken);
    const res = yield retry(3, 1000, getStoreApi, token);
    yield put(actions.getStoreSuccess(res.data));
  } catch (e) {
    yield put(actions.getStoreFailure());
  }
}

export function* watchGetStoreSaga() {
  yield takeLatest(actions.getStore.type, getStoreSaga);
}
