import { getPersistor } from "../configureStore";
import { takeLatest, call, delay } from "redux-saga/effects";
import { remoteLoginSuccess } from "../actions/auth-actions";
function* remoteLoginSaga() {
  yield delay(5000);
  const persistor = getPersistor();
  persistor.pause();
  yield call(() => persistor.purge());
  localStorage.clear();
}

export function* watchRemoteLoginSaga() {
  yield takeLatest(remoteLoginSuccess.type, remoteLoginSaga);
}
