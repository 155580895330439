import { select, takeLatest, put, call } from "redux-saga/effects";
import { selectToken } from "../../../redux-store/selectors";
import { canChooseReasonAPI } from "../../axios/canChooseReason";
import {
  canChooseReason,
  canChooseReasonFailure,
  canChooseReasonSuccess
} from "../actions/ordersActions";
import { canChooseReasonAction } from "../../constants/actions";
import { union } from "ts-action";
const actionType = union({ canChooseReason });

function* canChooseReasonSaga(action: typeof actionType) {
  try {
    const { orderId } = action.payload;
    const token = yield select(selectToken);
    const res = yield call(canChooseReasonAPI, token, orderId);
    yield put(canChooseReasonSuccess(res.data.show));
  } catch (e) {
    yield put(canChooseReasonFailure(e));
  }
}

export function* watchCanChooseReasonSaga() {
  yield takeLatest(canChooseReasonAction.requested, canChooseReasonSaga);
}
