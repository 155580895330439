import { reducer, on } from "ts-action";
import { canChooseReasonSuccess } from "../actions/ordersActions";
import { canChooseReasonAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";

interface IState {
  mustChooseReason: boolean;
}
export const canChooseReasonReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(canChooseReasonSuccess, (state, { payload }) => ({
        ...state,
        mustChooseReason: payload
      }))
    ],
    {
      mustChooseReason: true
    }
  ),
  canChooseReasonAction
);
