export default {
  LateReplyReason:
    "لقد تم ايقاف الطلبات اوتوماتيكيا بسبب التأخر في قبول الطلبات",
  Reopen: "اعادة تشغيل الطلبات",
  NoConnectionReason: " الطلبات متوقفة نتيجة تعذر الآتصال بالانترنت",
  NoConnectionSolution:
    "حاول إعادة الاتصال بالانترنت و ستسمع صوت الاشعار عند وصول  طلب جديد",
  NoOrdersReason: "لا توجد طلبات حالية",
  NoOrdersSolution: "ستسمع صوت الاشعار عند وصول  طلب جديد ",
  NoOrdersReceivedReason: "المطعم مغلق ولا يستقبل طلبات حاليا",
  NoOrdersReceivedSolution:
    "ستسمع صوت الاشعار عند وصول  طلب جديد عند تشغيل المطعم",

  StoppedOrders: "لقد تم ايقاف الطلبات",
  ReOrders: "إعادة تشغيل الطلبات",
  ThereIsOrders: "يوجد طلبات قيد التحضير حالياً",
  ThereAreNewOrders: "يوجد طلبات جديده"
};
