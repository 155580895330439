import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import {
  invalidPhoneFormat,
  isRequired,
  notAPhoneNumber,
  tooShortField
} from "../i18n/strings/ManagePoints";
import i18n from "../i18n/i18n";
import { MANAGE_POINTS } from "../i18n/strings/App";
export const required = (value: undefined) =>
  value !== undefined ? undefined : i18n.t(isRequired, { ns: MANAGE_POINTS });
export const minNumber = (value: number) =>
  value && value > 0 ? undefined : "Value can't be 0";
export const maxLength = (max: number) => (value: string | any[]) =>
  value && value.length > max ? tooShortField : undefined;
export const minLength = (min: number) => (value: string | any[]) =>
  value && value.length < min ? `Must be at least ${min} numbers` : undefined;
export const number = (value: any) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const minValue = (min: number) => (value: number) =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const maxValue = (max: number) => (value: number) =>
  value && value > max ? `Must be at most ${max}` : undefined;

export const phoneValidator = (countryCode: CountryCode) => (
  phoneNumber: string
) => {
  try {
    const phone = parsePhoneNumber(phoneNumber, countryCode);
    if (!phone.isValid()) {
      return invalidPhoneFormat;
    }
    return undefined;
  } catch (e) {
    return notAPhoneNumber;
  }
};
