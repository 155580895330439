import axios from "./axios";
import { branchOrderingStatus } from "./requests";
import { OrderingStatus } from "../components/AvailableRequests/AvailableRequestsModal";
export interface IGetBranchOrderingStatusRes {
  pickup_status: OrderingStatus;
  delivery_status: OrderingStatus;
}
export const getBranchOrderingStatus = (token: string, branch_id: string) => {
  return axios.get<IGetBranchOrderingStatusRes>(branchOrderingStatus, {
    params: {
      branch_id
    },
    headers: {
      token
    }
  });
};
