import axios from "./axios";
import * as Requests from "./requests";

export enum OrderStatusForReason {
  rejected = "rejected",
  canceled_by_cashier = "canceled_by_cashier"
}

export const getReasonsForDeliveryAPI = (
  token: string,
  order_status: OrderStatusForReason
) => {
  return axios.get(Requests.getDeliveryReasonAPI, {
    headers: {
      token
    },
    params: {
      order_status
    }
  });
};
