import axios from "./axios";
import * as request from "./requests";
import { OrderingStatus } from "../components/AvailableRequests/AvailableRequestsModal";

export const editOrderingStatus = (
  token: string,
  branch_id: string,
  status: OrderingStatus,
  reason_id?: number,
  interval?: string
) => {
  return axios.put<{ pickup_status: OrderingStatus }>(
    request.pauseOrderingStatus,
    {
      branch_id,
      status,
      reason_id,
      interval
    },
    {
      headers: {
        token
      }
    }
  );
};
