import { getStoreAction } from "../../constants/actions";
import { payload, action } from "ts-action";
import { IStore } from "../../../data-model/types";

export const getStore = action(getStoreAction.requested);
export const getStoreSuccess = action(
  getStoreAction.fulfilled,
  payload<IStore>()
);
export const getStoreFailure = action(getStoreAction.rejected);
