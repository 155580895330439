import { action, payload } from "ts-action";
import {
  getBranchAvgPrepTimeAction,
  changeBranchAvgPrepTimeAction
} from "../../constants/actions";
import { IBranchAvgPrepTime } from "../../axios/branchAveragePrepTime";
import { optAction } from "./lib";

export const getBranchAvgPrepTime = optAction(
  getBranchAvgPrepTimeAction.requested
);
export const getBranchAvgPrepTimeSuccess = action(
  getBranchAvgPrepTimeAction.fulfilled,
  payload<IBranchAvgPrepTime>()
);

export const getBranchAvgPrepTimeFailure = action(
  getBranchAvgPrepTimeAction.rejected
);

export const changeBranchAvgPrepTime = optAction(
  changeBranchAvgPrepTimeAction.requested,
  payload<IBranchAvgPrepTime>()
);
export const changeBranchAvgPrepTimeSuccess = action(
  changeBranchAvgPrepTimeAction.fulfilled,
  payload<IBranchAvgPrepTime>()
);
export const changeBranchAvgPrepTimeFailure = action(
  changeBranchAvgPrepTimeAction.rejected,
  payload<IBranchAvgPrepTime>()
);
