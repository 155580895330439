import * as React from 'react';
import * as styles from './styles.module.sass';



export const PhoneNumberItem: React.FC = ({children}) => {
  return (
    <a href={`tel:${children}`} className={styles.phoneNumberWrapper}>
      <span className={styles.phoneIcon}>
        <img src={require("../../../../assets/phone-call-white.svg")} />
      </span>
      <p className={styles.phoneNumber}>{children}</p>
    </a>
  );
};
