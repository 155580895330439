import { select, takeLatest, put, call } from "redux-saga/effects";
import { selectToken } from "../../../redux-store/selectors";
import { getReasonsForDeliveryAPI } from "../../axios/getReasonsForDelivery";
import {
  getDeliveryReasons,
  getDeliveryReasonsFailure,
  getDeliveryReasonsSuccess
} from "../actions/ordersActions";
import { getDeliveryReasonsAction } from "../../constants/actions";
import { union } from "ts-action";
const actionType = union({ getDeliveryReasons });

function* getDeliveryReasonsSaga(action: typeof actionType) {
  try {
    const { order_status } = action.payload;
    const token = yield select(selectToken);
    const res = yield call(getReasonsForDeliveryAPI, token, order_status);
    yield put(getDeliveryReasonsSuccess(res.data));
  } catch (e) {
    yield put(getDeliveryReasonsFailure(e));
  }
}

export function* watchGetDeliveryReasonsSaga() {
  yield takeLatest(getDeliveryReasonsAction.requested, getDeliveryReasonsSaga);
}
