import * as React from "react";
import i18n from "./i18n/i18n";

export const getRtlClass = stylesModule => {
  const isRtl = i18n.language === "ar";
  const rtlClass = stylesModule.rtl;
  const hasRtlClass = !!stylesModule.rtl;
  const shouldReturnRtlClass = isRtl && hasRtlClass;
  return shouldReturnRtlClass ? rtlClass : "";
};

export const useRtlClass = (
  stylesModule: { [x: string]: string } = { rtl: "rtl" }
) => React.useMemo(() => getRtlClass(stylesModule), [i18n.language]);
export const useCurrentLang = () => React.useMemo(() => i18n.language, [i18n]);

export const useIsArabic = () => Boolean(useRtlClass({ rtl: 'true' }));
