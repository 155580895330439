import { DeliveryMethods } from "../../Pickup-App/axios/changeDeliveryMethod";

export interface ICashier {
  integration_id: string;
  phoneNumber: string;
  updated_at: number;
  branch_id: string;
  shopxId: string;
  name: string;
  created_at: number;
  id: string;
  password: string;
  monthlyOperationsCount?: number;
  rank?: number;
  offline_loyalty_status?: "service_enabled" | "service_disabled";
}

export enum DeliveryManagementStatus {
  enabled = "enabled",
  disabled = "disabled"
}

export interface IBranchOrdering {
  lng: number;
  updated_at: number;
  delivery_method: DeliveryMethods;
  created_at: number;
  pickup_prep_min: number;
  id: string;
  pickup_prep_max: number;
  pickup_status: string;
  delivery_status: string;
  lat: number;
  updadted_at: number;
  delivery_management_status: DeliveryManagementStatus;
  dispatched_to_third_party_delivery?: number;
}

export interface IFailedActivityData {
  countryCode: string;
  id: string;
  phoneNumber: string;
  receipt_code: string;
  receipt: number;
  call_center: number;
}

export interface IFailedActivity {
  operation: string;
  service: string;
  timestamp: number;
  data: IFailedActivityData;
  message: string;
  failed: 1;
}

export interface IStore {
  allow_receipt_code: number;
  facebook_link: string;
  bio: string;
  created_at: number;
  converting_ratio: number;
  country_code: string;
  country_iso_code: string;
  background_color: string;
  updated_at: number;
  expiration_period: number;
  instagram_link: string;
  name: string;
  location: string;
  cover_image: string;
  id: string;
  status: number;
  store_image: string;
}

export interface IBranch {
  integration_id?: string;
  address: string;
  updated_at: number;
  numbers: string[];
  name: string;
  created_at: number;
  id: string;
  type?: number;
  call_center?: 1;
  deleted?: true;
  delivery_management_status?: string;
}

export interface ICategory {
  name: string;
  created_at: number;
  id: string;
  updated_at: number;
  type?: number;
}

export interface IStoreItem {
  image: string;
  updated_at: number;
  price: number;
  points?: number;
  is_in_loyalty_program: number;
  name: string;
  integration_id?: string;
  created_at: number;
  id: string;
  category: string;
  quantity?: number;
}

export interface ICustomer {
  shopxId: string;
  country_code: string;
  created_at: number;
  phone_number: string;
  id: string;
  updated_at: number;
  lastOperationPoints: number;
  visits: number;
  lastOperationTime: number;
  points: number;
  lastOperationCost: number;
  name: string;
  image?: string;
  seen?: number;
  birth_date?: number;
  offer_sms_counter?: number;
  facebook_id?: string;
  district_code?: string;
  email?: string;
  last_review_time?: number;
}

export interface IAddPointsOperation {
  store_id: string;
  branch_integration_id: string;
  created_at: number;
  remaining?: number;
  points: number;
  expires_at?: number;
  expired?: number;
  phoneNumber: string;
  updated_at: number;
  branch_id: string;
  price: number;
  countryCode: string;
  receipt_code: string;
  cashier_integration_id: string;
  operation_id: string;
  state?: number;
  id: string;
  customer: ICustomer;
  status: number;
  seen?: number;
}

export interface IRedeemRewardOperation {
  created_at: number;
  id: string;
  updated_at: number;
  points?: number;
  customer: ICustomer;
  item: string;
}

export interface IRedeemGiftInboxOperation {
  created_at: number;
  id: string;
  updated_at: number;
  customer: ICustomer;
  gift: string;
}
