import { ActionCreator } from "ts-action";

type ActionCreatorType = ActionCreator<
  string,
  (payload: any) => { type: string; payload: any }
>;

export class SocketEvent {
  public label: string;
  public actionCreator: ActionCreatorType;
  constructor(label: string, actionCreator: ActionCreatorType) {
    this.label = label;
    this.actionCreator = actionCreator;
  }
}
