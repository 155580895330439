import { useTranslation } from "./useTranslation";
import { format } from "date-fns";
import { arSA, enUS } from "date-fns/locale";
import { useMemo } from "react";

export const useDateFnsLocale = () => {
  const { i18n } = useTranslation();

  return i18n.language === "ar" ? arSA : enUS;
};

export const useLocalizedFormat: typeof format = (date, formatTo, options) => {
  const localeToUse = useDateFnsLocale();
  try {
    return useMemo(
      () => format(date, formatTo, { locale: localeToUse, ...options }),
      [date, formatTo, options]
    );
  } catch (e) {
    return "";
  }
};
