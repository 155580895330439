import React from "react";
import * as styles from "./styles.module.sass";
import { useTranslation } from "../../../../../../hooks/useTranslation";
import * as Strings from "../../../../../../i18n/strings/newOrder";
import { hot } from "react-hot-loader";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { IncreaseDecreaseInput } from "../../../../../../Shared-Components/FormElements/increase-decrease-input";
import { IBranchAvgPrepTime } from "../../../../../../axios/branchAveragePrepTime";
import { storedOrderType } from "../../../../../../redux/reducers/getOrdersReducer";
import { useCalculateEstimatedArrivalTime } from "../../../../NewOrderItem/pickupTime";
import { useRtlClass } from "../../../../../../../lib";

interface IProps {
  goToInitialChoices: () => void;
  onSubmit: (data: IBranchAvgPrepTime) => void;
  order: storedOrderType;
}
const EditPrepTime: React.FC<InjectedFormProps & IProps> = props => {
  const { goToInitialChoices, handleSubmit, onSubmit, order } = props;
  const { t } = useTranslation("newOrder");
  // const orderDate = useMemo(() => fromUnixTime(order.created_at / 1000), [
  //   order.id
  // ]);
  const timeLeft = useCalculateEstimatedArrivalTime(order);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`${styles.wrapper} ${useRtlClass(styles)}`}
    >
      <div className={styles.editWrapper}>
        <div className={styles.edit} onClick={goToInitialChoices}>
          <img
            className={styles.arrowRight}
            src={require("../../../../../../assets/arrow-right.svg")}
          />
          <span className={styles.title}>{t(Strings.preparationTime)}</span>
        </div>
        <div className={styles.edit}>
          <img
            className={styles.clockIcon}
            src={require("../../../../../../assets/clock.svg")}
          />
          <div className={styles.timeWrapper}>
            <span className={styles.timeTitle}>
              {t(Strings.clientArrivesAfter)}
            </span>
            <span className={styles.secondTitle}>{timeLeft}</span>
          </div>
        </div>
      </div>
      <div className={styles.secondWrapper}>
        <span className={styles.timeEditTitle}>
          {t(Strings.timeAdjustmentTo)}
        </span>
        <form>
          <div className={styles.fieldWrapper}>
            <Field
              component={IncreaseDecreaseInput}
              label={t(Strings.minute)}
              name="pickup_prep_max"
            />
          </div>
        </form>
        <div className={styles.btnWrapper}>
          <button
            type="submit"
            className={styles.save}
            children={t(Strings.save)}
          />
        </div>
      </div>
    </form>
  );
};

export default hot(module)(
  reduxForm({
    form: "editForm"
  })(EditPrepTime as any) as any
);
