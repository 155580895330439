import firebase from "firebase/app";
import { retry, delay } from "redux-saga/effects";

export const updateHandler = async () => {
  const latestVersion = await getLatestVersion();
  const currentVersion = localStorage.getItem("appVersion");
  if (latestVersion && currentVersion !== latestVersion) {
    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      localStorage.removeItem("persist:syncReducer");
      localStorage.setItem("appVersion", latestVersion);
      await Promise.all(registrations.map(reg => reg.update()));
    }
  }
};

const getLatestVersion = async () => {
  const db = firebase.firestore();
  const versionCollection = db.collection("version");
  const cashierVersionDocument = versionCollection.doc(
    process.env.VERSION_FIELD
  );

  return (await cashierVersionDocument.get()).data()?.current;
};

const SECOND = 1000;
const MINUTE = 60 * SECOND;

const UPDATE_INTERVAL = 10 * MINUTE;
const RETRY_INTERVAL = 10 * SECOND;

export function* checkForUpdateSaga() {
  while (true) {
    yield delay(UPDATE_INTERVAL);
    yield retry(5, RETRY_INTERVAL, updateHandler);
  }
}
