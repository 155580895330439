import {
  login,
  password,
  phoneNumber,
  loginFailed,
  loginSuccessful,
  hadProblems,
  contactSupport,
  contactSupportTitle,
  contact,
  contactFailure,
  contactLoading,
  confirm
} from "../strings/Login";

export default {
  [phoneNumber]: "Phone number",
  [password]: "Password",
  [login]: "Login",
  [loginSuccessful]: "Login was successful",
  [loginFailed]: "Incorrect phone number or password",
  [hadProblems]: "Have problems?",
  [contactSupport]: "Contact support",
  [contactSupportTitle]:
    "Please confirm the help request and the support team contact you immediately",
  [contact]:
    "Your assistance request has been confirmed, and we will contact you immediately",
  [contactLoading]: "Submitting your request...",
  [contactFailure]:
    "Failed to submit the assistance request please try again later",
  [confirm]: "Submit help request"
};
