import React from "react";
import * as styles from "./styles.module.sass";
import { GenericButtonWithoutIcon } from "../../../../../../Shared-Components/GenericButtonWithoutIcon";
import { useTranslation } from "../../../../../../hooks/useTranslation";
import * as Strings from "../../../../../../i18n/strings/newOrder";
import { useRtlClass } from "../../../../../../../lib";

interface IProps {
  onClose: () => void;
  goToPrepTime: () => void;
  // goToContactSupport: () => void;
  goToCallClient: () => void;
  goToRejectOrder: () => void;
}

const Settings: React.FC<IProps> = props => {
  const { t } = useTranslation("newOrder");

  const {
    goToPrepTime: showHandler,
    // goToContactSupport: contactSupportHandler,
    goToCallClient: clientHandler,
    goToRejectOrder: cancelHandler
  } = props;
  return (
    <>
      <div className={`${styles.contentWrapper} ${useRtlClass(styles)}`}>
        <div className={styles.content} onClick={showHandler}>
          <span className={styles.title}> {t(Strings.preparationTime)} </span>
          <img
            className={styles.arrow}
            src={require("../../../../../../assets/arrow-left.svg")}
          />
        </div>
        <div className={styles.content} onClick={clientHandler}>
          <span className={styles.title}>{t(Strings.contactClient)} </span>
          <img
            className={styles.arrow}
            src={require("../../../../../../assets/arrow-left.svg")}
          />
        </div>
        {/*<div className={styles.content} onClick={contactSupportHandler}>*/}
        {/*  <span className={styles.title}> {t(Strings.contactSupport)} </span>*/}
        {/*  <img*/}
        {/*    className={styles.arrow}*/}
        {/*    src={require("../../../../../../assets/arrow-left.svg")}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <GenericButtonWithoutIcon
        onClick={cancelHandler}
        label={t(Strings.cancel)}
        className={styles.btn}
      />
    </>
  );
};

export default Settings;
