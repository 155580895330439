import { reducer, on } from "ts-action";
import { getDeliveryReasonsSuccess } from "../actions/ordersActions";
import { getDeliveryReasonsAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { IReasons } from "../../constants/types";

interface IDeliveryReasons {
  deliveryReasons: number[];
  deliveryReasonsById: { [x: number]: IReasons };
}
export const getDeliveryReasonsReducer = withLoadingReducer<IDeliveryReasons>(
  reducer<IDeliveryReasons>(
    [
      on(getDeliveryReasonsSuccess, (state, { payload }) => ({
        ...state,
        deliveryReasons: payload.map(i => i.id),
        deliveryReasonsById: payload.reduce(
          (acc, next) => ({
            ...acc,
            [next.id]: next
          }),
          {}
        )
      }))
    ],
    {
      deliveryReasons: [],
      deliveryReasonsById: {}
    }
  ),
  getDeliveryReasonsAction
);
