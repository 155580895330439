import React, { useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { useSelect } from "../../helpers/use-select";
import { useDispatch } from "react-redux";
import {
  remoteLoginSuccess,
  setSelectedCashierBranch
} from "../../redux-store/actions/auth-actions";
import { useGetAssignedBranches } from "../../web-components/Login/SelectBranchInLogin/use-get-assigned-branches";
import { IBranchWithPermissions } from "../../axios/getAssignedBranches";

export const RemoteLogin: React.FC<RouteComponentProps<{
  token?: string;
  branch_id?: string;
  store_pos?: string;
  store_id?: string;
  cashier_id?: string;
}>> = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const branch_id = params.get("branch_id");
  const cashier_id = params.get("cashier_id");
  const store_id = params.get("store_id");
  const store_pos = params.get("store_pos") || undefined;
  const { isLoggedIn } = useSelect(state => state.authReducer);
  const dispatch = useDispatch();
  const { assignedBranches, success } = useGetAssignedBranches(
    token || undefined
  );

  useEffect(() => {
    if (
      token &&
      branch_id &&
      cashier_id &&
      store_id &&
      success &&
      assignedBranches.length
    ) {
      dispatch(
        remoteLoginSuccess({
          token,
          store_id,
          store_pos,
          cashier_id,
          branch_id
        })
      );
      dispatch(
        setSelectedCashierBranch(
          assignedBranches.find(
            a => a.id === branch_id
          ) as IBranchWithPermissions
        )
      );
    }
  }, [token, branch_id, assignedBranches, success]);

  return isLoggedIn ? <Redirect to={"/"} /> : <>Hello there kiddo</>;
};
