import { withLoadingReducer } from "../../Pickup-App/redux/reducers/withLoadingState";
import { on, reducer } from "ts-action";
import { logCurrentAppVersionAction } from "../../constants";
import { logCurrentAppVersionSuccess } from "../actions";
import { TBranch, TCashier } from "../../types";

type LogCurrentAppVersionState = {
  branch?: TBranch;
  cashier?: TCashier;
};
export default withLoadingReducer<LogCurrentAppVersionState>(
  reducer<LogCurrentAppVersionState>(
    [
      on(logCurrentAppVersionSuccess, (state, { payload }) => ({
        ...state,
        ...payload
      }))
    ],
    {
      cashier: {
        store_id: "",
        phoneNumber: "",
        updated_at: 0,
        offline_loyalty_status: "service_enabled",
        name: "",
        store_time_zone: "",
        created_at: 0,
        id: ""
      },
      branch: {
        address: "",
        app_version: "",
        created_at: 0,
        detailed_address: "",
        en_detailed_address: "",
        en_name: "",
        id: "",
        integration_id: "",
        last_sync_change: 0,
        last_sync_time: 0,
        lat: 0,
        lng: 0,
        name: "",
        numbers: [],
        offline_loyalty_status: "",
        updated_at: 0,
        ar_detailed_address: "",
        ar_name: ""
      }
    }
  ),
  logCurrentAppVersionAction
);
