import axios from "./axios";
import * as request from "./requests";

export const reEnableOrderingStatus = (token: string, branch_id: string) => {
  return axios.put(
    request.reEnableOrderingStatus,
    {
      branch_id
    },
    {
      headers: {
        token
      }
    }
  );
};
