import { reducer, on } from "ts-action";
import { setAppActiveState } from "../../../redux-store/actions";
import { receiveNewOrder } from "../actions/socket-actions";
import { offlineDismiss } from "../actions/lockScreenActions";

const initialState = {
  // this number will hold the number of orders received
  // while the user is inactive
  // this number will change due to 2 events
  // 1: In the event of receiving and order
  // while the user has not used the app in
  // 120 seconds, will increment by one by each
  // 2: In the event of the user having reactivated
  // meaning having clicked tapped or touched around the app
  // This number will be used in the LockScreen Component to
  ordersReceivedDuringInactivity: 0,
  userActive: true,
  offline_dismiss: false
};

export const lockScreenReducer = reducer<typeof initialState>(
  [
    on(setAppActiveState, (state, { payload: userActive }) => ({
      ...state,
      userActive,
      ordersReceivedDuringInactivity: userActive
        ? 0
        : state.ordersReceivedDuringInactivity
    })),
    on(receiveNewOrder, ({ ordersReceivedDuringInactivity, userActive }) => ({
      userActive,
      offline_dismiss: false,
      ordersReceivedDuringInactivity: userActive
        ? 0
        : ordersReceivedDuringInactivity + 1
    })),
    on(offlineDismiss, (state, { payload }) => ({
      ...state,
      offline_dismiss: payload,
      userActive: payload
    }))
  ],
  initialState
);
