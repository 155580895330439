import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { useTranslation } from "../../../../../hooks/useTranslation";
import * as Strings from "../../../../../i18n/strings/newOrder";
import EditPrepTime from "./EditPrepTime";
import Settings from "./Settings";
// import ContactSupport from "./ContactSupport";
import ContactClient from "./ContactClient";
import CancellingOrder from "./CancellingOrder";
import { storedOrderType } from "../../../../../redux/reducers/getOrdersReducer";
import { useRtlClass } from "../../../../../../lib";
import { hot } from "react-hot-loader";
import { useOrderAvgPrepTime } from "../../hooks/use-order-prep-time";
import { useDispatch } from "react-redux";
import { OrderStatus, OrderType } from "../../../../../constants/types";
import { OrderStatusForReason } from "../../../../../axios/getStatusReason";
import {
  // canChooseReason,
  getDeliveryReasons,
  getStatusReason
} from "../../../../../redux/actions/ordersActions";

interface IProps {
  onClose: () => void;
  order: storedOrderType;
  initialScreen: OrderSettingsScreens;
}

export enum OrderSettingsScreens {
  initialChoices = "initialChoices",
  contactSupport = "contactSupport",
  prepTime = "prepTime",
  callClient = "callClient",
  rejectOrder = "rejectOrder"
}

const OrderSettings: React.FC<IProps> = props => {
  const { t } = useTranslation("newOrder");
  const { onClose, order } = props;
  const [currentScreen, setCurrentScreen] = useState<OrderSettingsScreens>(
    props.initialScreen
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const getReasonsAction =
      order.order_type === OrderType.delivery
        ? getDeliveryReasons
        : getStatusReason;
    const { rejected, canceled_by_cashier } = OrderStatusForReason;
    const { seen, sent, received } = OrderStatus;
    const { status } = order;
    const isReject = status === seen || status === received || status === sent;

    const nextState = isReject ? rejected : canceled_by_cashier;
    dispatch(
      getReasonsAction({
        order_status: nextState
      })
    );
    // dispatch(canChooseReason({ orderId: order.id }));
  }, []);
  const { avgPrepTime, changeOrderAvgPrepTime } = useOrderAvgPrepTime(order.id);

  const handleSubmitAvgPrepTime = data => {
    changeOrderAvgPrepTime(data);
    onClose();
  };
  const goToCallClient = () =>
    setCurrentScreen(OrderSettingsScreens.callClient);
  // const goToContactSupport = () =>
  //   setCurrentScreen(OrderSettingsScreens.contactSupport);
  const goToInitialChoices = () =>
    setCurrentScreen(OrderSettingsScreens.initialChoices);
  const goToRejectOrder = () =>
    setCurrentScreen(OrderSettingsScreens.rejectOrder);
  const goToPrepTime = () => setCurrentScreen(OrderSettingsScreens.prepTime);
  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      onClose={onClose}
      open={true}
    >
      <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
        {currentScreen !== OrderSettingsScreens.rejectOrder && (
          <div className={styles.settingsWrapper}>
            <span className={styles.settingsTitle}>
              {t(Strings.orderSettings)}:
            </span>
            <div className={styles.codeWrapper}>
              <span className={styles.code}>{order.order_code}</span>
            </div>
            <img
              onClick={onClose}
              className={styles.closeIcon}
              src={require("../../../../../assets/close.svg")}
            />
          </div>
        )}
        {currentScreen === OrderSettingsScreens.initialChoices && (
          <>
            <Settings
              goToCallClient={goToCallClient}
              // goToContactSupport={goToContactSupport}
              goToPrepTime={goToPrepTime}
              onClose={onClose}
              goToRejectOrder={goToRejectOrder}
            />
          </>
        )}
        {currentScreen === OrderSettingsScreens.prepTime && (
          <EditPrepTime
            order={order}
            initialValues={avgPrepTime}
            onSubmit={handleSubmitAvgPrepTime}
            goToInitialChoices={goToInitialChoices}
          />
        )}
        {/*{currentScreen === OrderSettingsScreens.contactSupport && (*/}
        {/*  <ContactSupport goToInitialChoices={goToInitialChoices} />*/}
        {/*)}*/}
        {currentScreen === OrderSettingsScreens.rejectOrder && (
          <CancellingOrder
            orderId={order.id}
            goToInitialChoices={goToInitialChoices}
            onClose={onClose}
            orders={order}
          />
        )}

        {currentScreen === OrderSettingsScreens.callClient && (
          <ContactClient
            phoneNumber={order.customer.phone_number}
            goToInitialChoices={goToInitialChoices}
          />
        )}
      </div>
    </Modal>
  );
};

export default hot(module)(OrderSettings);
