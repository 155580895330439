export const LateReplyReason = "LateReplyReason";
export const Reopen = "Reopen";

export const NoConnectionReason = "NoConnectionReason";
export const NoConnectionSolution = "NoConnectionSolution";

export const NoOrdersReason = "NoOrdersReason";
export const NoOrdersSolution = "NoOrdersSolution";

export const NoOrdersReceivedReason = "NoOrdersReceivedReason";
export const NoOrdersReceivedSolution = "NoOrdersReceivedSolution";

export const StoppedOrders = "NoOrdersReceivedReason";
export const ReOrders = "ReOrders";
export const ThereIsOrders = "ThereIsOrders";
export const ThereAreNewOrders = "ThereAreNewOrders";
