import {
  login,
  password,
  phoneNumber,
  loginSuccessful,
  loginFailed,
  hadProblems,
  contactSupport,
  contactSupportTitle,
  contact,
  confirm,
  contactLoading,
  contactFailure
} from "../strings/Login";

export default {
  [phoneNumber]: "رقم الهاتف",
  [password]: "كلمة المرور",
  [login]: "تسجيل الدخول",
  [loginSuccessful]: "تم تسجيل الدخول بنجاح",
  [loginFailed]: "رقم الهاتف او كلمة المرور غير صحيحة",
  [hadProblems]: "هل واجهت مشكلة؟",
  [contactSupport]: "اتصل بالدعم",
  [contactSupportTitle]:
    " قم بتأكيد طلب المساعدة و سوف يقوم احد عملائنا بالتصال بك علي الفور",
  [contact]: "تم تأكيد طلب المساعدة، سيقوم احد عملائنا بالتصال بك علي الفور",
  [contactLoading]: "جاري ارسال طلبك اﻵن...",
  [contactFailure]:
    "فشل في ارسال طلب المساعدة رجاءاً قم بالمجاولة مجدداً في وقت لاحق.",

  [confirm]: "إرسال طلب المساعدة"
};
