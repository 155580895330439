export const phoneNumber = "phoneNumber";
export const password = "password";
export const login = "login";
export const loginSuccessful = "loginSuccessful";
export const loginFailed = "loginFailed";
export const hadProblems = "hadProblems";
export const contactSupport = "contactSupport";
export const contactSupportTitle = "contactSupportTitle";
export const contact = "contact";
export const contactFailure = "contactFailure";
export const contactLoading = "contactLoading";
export const confirm = "confirm";
